.vedio-container{position: relative;height: 550px;width: 100%;}
.vedio{position:absolute;top:0;left:0;height:100%;width:100%;object-fit:cover}
.popup-img{position: absolute;right: 0;top: 50%;transform: translateY(-50%);height: 70px;cursor: pointer;height: 100px;z-index: 9;}

.admission{color: #5959C5;font-size: 22px;font-weight: 600;}
.modal-dialog{width:25%}

@media screen and (max-width:425px) {
    .mobile-img{height: 570px;}
    .popup-img{transform: translateY(-35%);
        height: 55px !important;
    }
    
}

