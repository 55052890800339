.carbg{position: relative;text-align: center;}
.carbg::after{content: "";position: absolute;bottom: 0;background: #5959C5;height: 350px;width: 100%;right: 0;z-index: -1;}
.login-placement{position: absolute; bottom: 22%;left: 50%;transform: translateX(-50%);}
.car{max-width:800px;}

@media screen and (max-width:550px) {
    .carbg{margin-bottom: 160px;}
    .carbg::after { bottom:0px;height: 150px;}
    .login-placement h3{font-size: 14px;}
    .login-placement{bottom: -90px;width: 100%;}
}