.mt-100{margin-top: 100px;margin-bottom: 100px;}
.rect{position: absolute;left: 75%;
    top: -75px;}
.rect2{position: absolute;
    left:0px;
    top: 0;}
.rect3{position: absolute;right: 0;bottom:-35px;}
.welcome p{text-align: start;}
.schedulecolor .DailySchedule{font-weight: 600 !important;}

@media screen and (max-width:1023px){
    .rect, .rect2, .rect3 { display: none;}

}
@media screen and (max-width:1000px){
    .welcome p { font-size: 13px;}
    .History .rectangle::after{width: 60px !important;}
}

@media screen and (max-width:400px){
    .mt-100{margin-top:80px;}   
    .mob-5{margin-bottom:60px;}
    .mt-margin{margin: 60px auto 0 !important;}
    .mt-margin-15{margin: 15px auto 0 !important;}
    
}