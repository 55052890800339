.choice{background: #FFF;padding:30px 50px;}
.choice p{color: #2D306F; font-size: 18px;}
.choicetitle{font-size:32px !important;font-weight: 600;color: #5959C5 !important;}
.choicedescription{font-size: 14px;color: #E2E2E2;}
.mob-pe-0{padding-right: 0;}
.num{font-size: 24px !important;background-color: #FFF;color: #5959C5 !important;border-radius: 50px;height: 50px;
    width: 50px;font-weight:800;text-align: center !important;padding: 8px 10px;box-shadow: 0px 0px 7px 0px #00000040;
}

.d-flex .fw-600.ms-3{padding:  0 0 10px 0 ;font-size: 20px;font-family: "Montserrat", sans-serif !important;}


    @media screen and (max-width:550px){
        .welcome p{font-size: 14px;}
        .choice{padding:20px;}
        .choicetitle{font-size:24px !important;}
        .choice p {font-size: 14px;}
        .mob-5{margin-bottom: 0;}
    }