.mesh-bg::after{content: ""; position: absolute;height: 100%;width: 100%;background-image: url(../../Assets/mesh2.png);
    background-size: contain;background-position: center;top:0px;left: 0;z-index:-1;}
    .mesh-bg{z-index: 0;position: relative;padding:100px 0 50px;}
    .leader-card p,  .leader-card2 p{font-size: 14px;padding-bottom: 15px;}
    .leader-card, .leader-card2{max-width: 470px;position: absolute;right: 0;top:50%;transform: translateY(-50%);}
    .leader-card{top: 43%;}
    .leader-card2{left:-160px;top: 42%;}
    .custom-width, .custom-width2{max-width: 1065px;width: 100%;margin: 0 auto;margin-bottom: 30px;padding-bottom: 60px;}    
    .custom-width2{margin: unset;margin-left: auto !important;}
    .about-leader p:nth-of-type(1) {text-align: center;text-transform: uppercase;font-weight: 600;color: #5959C5 !important;padding-bottom: 5px;margin-top: 15px;font-size: 20px;}
    .about-leader p:nth-of-type(2){color: #000;opacity: 0.4;text-align: center;text-transform: uppercase;font-weight: 500;font-size: 18px;} 
.leader-img-new{display: none;}
    @media screen and (max-width:1300px){
        .leader-img{width: 100%;}
        .leader-card, .leader-card2 {max-width: 350px;}
        .leader-card2 { left: 0; }
    }
    @media screen and (max-width:550px){
        .leader-img-new{display: block;width: 100%;margin-bottom: 20px;}
        .leader-img{display: none;}
    .leader-card p {font-size: 12px !important;}
    .leader-card, .leader-card2{position: unset;transform: unset;}
    }