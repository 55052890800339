.flip-card {
    width: 300px;
    height: 300px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front img, .flip-card-back img {width: 100%;max-width: 235px;}
  
  .flip-card-back {   
    transform: rotateY(180deg);
  }

  .margin-div{margin:100px auto;}
  .housewidth{max-width: 800px;margin: 0 auto;}
  .housecenter{justify-content: center;}

  .housecard-margin{margin-bottom:175px;position: relative;}

  .housecard-margin::before{content:"";position: absolute;    height: 350px;
    max-width: 120px;
    width: 100%;
    left: 0;
    bottom: -170px;background-image: url(../../Assets/star.png);background-repeat: no-repeat;background-size: contain;}


  @media screen and (max-width:1300px) {
    .flip-card { width: 209px; height: 300px;margin: 15px auto;}
    .margin-div {margin: 40px auto;}
  }
  @media screen and (max-width:550px) {
    .flip-card { width:150px; height: 200px;}
    .margin-div {
      margin: 20px auto;
  }

  }