.mt-100{margin-top: 100px;margin-bottom: 100px;}
.rect{position: absolute;left: 75%;
    top: -75px;}
.rect2{position: absolute;
    left:0px;
    top: 0;}
.rect3{position: absolute;right: 0;bottom:-35px;}
.welcome p{text-align: justify !important;max-width: 545px;}
.welcome p{color:#56588a !important;font-weight: 400 !important;}
.top200{margin-top: 150px !important;}
@media screen and (max-width:1023px){
    .rect, .rect2, .rect3 { display: none;}
    .welcome p{text-align:start !important;}
}

@media screen and (max-width:550px){
    .top200{margin-top: 60px !important;}
    .mt-marginn {
        margin:100px 0 0 !important;
    }
}

@media screen and (max-width:400px){
    .mt-100{margin-top:80px;}   

    .mob-5{margin-bottom:120px;}
    .mt-marginn {
        margin:130px 0 0 !important;
    }
    .welcome p{padding: 0 10px 0 0;  }
}