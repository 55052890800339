.board{margin: 10px auto;width: 100%;max-width: 315px;}
.Announcement{padding: 50px 0;}
.mesh{position: absolute;top:-50px;width: 100%;}
.bulb{position: absolute;left:0px;top:-50px;}
.board-large {
   
    margin: 0 -15px;
}

.newsimg img{height: 560px;max-width: 800px;margin: 0 auto;}

.mesh-news-bg{background-image: url(../Assets/mesh.svg);background-position: center;background-size: contain;}

.board-small-container {
    display: flex;
    justify-content: center;
    align-items: center; /* Center vertically */
    height: 100%; /* Set the container height to match the slide height */
}

.board-small {
    width: 65%;
    max-width: 350px;
    height: auto;
    margin-top: 65px;
}

/* .annoncement-description{position: absolute;top:15px;left: 15px;} */

@media screen and (max-width:1000px) {
    .board-large {width: 100%; max-width:100%;}
    .newsimg img{height: unset;max-width: unset;}
    .board-small {width:100%;margin-top: 0; max-width:100%;}
    .board{margin:5px;}
    .Announcement{padding: 100px 25px;}
    .board-large {
        margin: 0 0;
    }
}

@media screen and (max-width:550px) {
.Announcement{padding: 20px 25px 0;}
.bulb{display: none;}
}