.History .rectangle::after{background: #5959C5;height:400px;width: 200px;position: absolute;content: "";left:0;bottom: 0;z-index:0;}
.histry .rectangle::after{left:18%;}

.History .rectangle img{z-index: 1;position: relative;}
.rectangle{text-align: center;}
@media only screen  and (max-width:1300px){
    .History .rectangle::after {
        left: 30px ;
    }
}
@media only screen  and (max-width:700px){
    .school-left { padding-left: 30px !important;padding-top: 0 !important;}
    .History .rectangle::after { width: 200px !important;left: 0;}
}

@media only screen  and (max-width:450px){
    .History .rectangle::after { left: 30px; width: 200px !important;
    }
}

