.ammission-mt{margin: 100px auto;}
.admission img{height:70px;}
.admission h3{font-size: 18px;line-height: 1.5; max-width: 310px;margin: 0 auto;}
.admission p{text-align: center;margin:0 auto;
    font-size: 16px;height: 80px;
    color: #2D306F;
    font-weight: 400;
    font-family: poppins;}

    .admission a{font-size: 14px;text-transform: uppercase;}


    @media screen and (max-width:1300px) {
        .admission h3{font-size: 16px;}
        .admission p{font-size: 14px;}
        .custom-card.flex-coloumn.admission{margin: 0;padding: 12PX;margin-bottom: 20px !important;}
        .admission p{height: 50px;}
    }
    @media screen and (max-width:550px) {
    .ammission-mt {margin: 40px auto;}

    }