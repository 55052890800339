.aboutGallery .tab-margin{position: absolute;top: 110px;display: flex;
    flex-wrap: wrap;}
.tab-margin .tab-label{margin-bottom: 20px;
    font-size: 14px;
    flex: 1 1 22%;
    border-radius: 0;
    text-align: center;
    box-sizing: border-box;}

    .tab-margin .tab-label:first-child{border-radius: 8px 0 0 8px;}
    .tab-margin .tab-label:last-child{border-radius: 0 8px 8px 0;}

    .mt-reverse2 {margin-top: -100px;}
    .min-img{padding:0 10px;border-radius:12%;}

    .tab-margin .tab-label:nth-of-type(4){border-radius: 0 8px 8px 0;}
    .tab-margin .tab-label:nth-of-type(5){border-radius: 8px 0 0 8px;}


