.blue-bg{background:#5959C5;padding: 50px 0;height: 350px;z-index: -1;}
.blue-content p,.blue-content h3{color: #FFF !important;margin-bottom:20px;text-align:initial;}
.blue-content span{display: block;}

.faculties1{position: absolute;left: 0;bottom: 30%;}
.faculties2{position: absolute;right:10%;top:20%;}
.faculties3{position: absolute;right:0;bottom: 0%;}

.white-bg{background: #FFF;padding: 20px;max-width: 800px;margin: 0 auto;}
.mt-margine-reverse{margin-top: -175px !important;margin-bottom: 100px;}

@media screen and (max-width:550px) {
    .mt-margine-reverse{margin-top: -120px !important;}
    .faculties1{display: none;}
}

