.lab p{border: 1.8px solid;
    border-image-source: linear-gradient(7.88deg, #FFFFFF 6.38%, #C1C1E9 94.26%);
    background: #FFF;
    color: #353597;
    font-weight: 700;
    padding: 10px 20px;
    position: absolute;
    bottom: -1px;
    max-width: 40%;
    font-size: 24px;
    width: 100%;
    text-align: center;}

    .tab-label{font-size: 22px;color: #353597;border-image-source: linear-gradient(7.88deg, #FFFFFF 6.38%, #C1C1E9 94.26%);
        background: #FFF;  font-weight: 700;
        padding: 10px 20px;border-radius:5px;width: 100%;max-width: 50%;}

        .tab-label.react-tabs__tab--selected{background-color: #5959C5;color: #fff;}
        .react-tabs__tab-list{border-bottom: 0;    display: flex;
            justify-content: center;text-align: center;
            align-items: center;
            gap: 15px;}

            .facilities-imgSport img{object-fit: contain !important;}
.top15{margin-top: -15%;}

@media screen and (max-width:770px) {

    .top15{margin-top: -25% !important;}
}

@media screen and (max-width:550px) {
    .tab-label {font-size: 16px;}
    .lab p{max-width: 50%;font-size: 16px;}
    .top15{margin-top: -55% !important;}
}