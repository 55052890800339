.blue-bg{background:#5959C5;padding: 50px 0;height: 350px;}
.blue-content p,.blue-content h3{color: #FFF !important;margin-bottom:20px;text-align:initial;}
.blue-content span{display: block;}
.blue-content h1{color: #FFF !important;}

.card{background-color: #FFF;border-radius: 15px;padding: 20px;gap:20px;box-shadow: 0px 0px 7px 0px #00000040;
}
.card2{background-color:#F8F8F8;border-radius: 6px;padding: 20px 10px;gap: 5px;box-shadow: 0px 0px 7px 0px #00000040;
}

.light-blue2{color: #2D306F;font-size: 18px;}
.card a{text-align: center;}

.card2 h3, .card2 p{color: #5959C5;text-align: center;font-weight: 700;}
.card h3{text-transform: uppercase;font-size: 20px;}
.card2 p {
    font-size: 14px;font-weight: 500;
}
.mt-reverse3{margin-top: -140px;}

.middesign, .rightdesign , .leftdesign{position: absolute;bottom: 0;}
.middesign{left: 50%;    transform: translateX(-60%);
    bottom: -80px;}
.rightdesign{right: 0;}
.leftdesign{left: 0;}

@media screen and (max-width:1300px){
    .card2 h3{font-size: 13px;}
    .card2 p{font-size: 12px;
        max-width: 90px;
        margin: 0 auto;}
    .card{padding: 20px 10px;}
    .card a{font-size: 12px !important;}
    .custom-btn{padding: 10px 5px;}
    .mt-reverse3 .card{margin-bottom: 15px;}
    .middesign, .rightdesign , .leftdesign{display: none;}
}

@media screen and (max-width:550px){
    .card2 h3 {font-size: 11px;}
    
    .mob-container .container{padding: 0 !important;}
}