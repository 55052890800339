.rect2{position: absolute;
    left:0px;
    top: 0;}

    .custom-card p{margin-bottom:0px;}
    .small-logo{height:250px;}
.crest p{color:#2D306F;padding-bottom:18px;font-size: 18px;}
.crest{margin: 80px 0 0;}
.crest p:last-child{padding-bottom: 0;}
    @media screen and (max-width:1000px) {
        .crest .custom-card{margin: 0;padding: 10px;margin-bottom: 20px !important;}
    }

    @media screen and (max-width:550px) {
        .crest .custom-card{margin: 20px 0 !important;}
        .small-logo{height: 220px;text-align: center;}
    }