.header .nav-link{color: #353597 !important;font-weight:500;position: relative;font-weight: 600;    margin: 0px 3px;}
.header .active.nav-link{border-bottom: 2px solid #353597;}
.header .container{max-width:1300px ;}
.header .dropdown-item.active, .header.dropdown-item:active{background-color: #353597 !important;color: #FFF !important;}
.header .nav-link:focus-visible{box-shadow: unset;}
.header .dropdown-item{color: #353597 !important;font-weight: 600;}

  @media (max-width: 1300px) {
    .header .navbar-brand img {height:58px;object-fit: contain;}  
    .header .nav-link {font-size: 16px;margin: 0;}
    .modal-dialog {width: 35%;}
  }

  @media (max-width: 1115px) {

    .header .nav-link {font-size: 13px;margin: 0;}
  }
  
  @media (max-width: 425px) {
    .header .navbar-brand img {height: 40px;}
    .nav-link, .dropdown-item{color: #353597 !important;font-weight:500;position: relative;}
    .btn-close:hover{color: #353597 !important;--bs-btn-close-color:#353597 !important}
    .offcanvas-backdrop{background-color: transparent;}

    .dropdown-item.active, .dropdown-item:active{background-color: #353597 !important;color: #FFF !important;}
  
  }
  
  /* Custom styles for the Navbar.Toggle button */
  .header .navbar-toggler:focus {box-shadow: unset;}  
  .header .custom-toggler {border: 0 !important;outline: 0 !important;background-color: transparent;cursor: pointer;}  
  .header .bar {width: 25px;height: 3px;background-color: #353597;margin: 5px 0;transition: background-color 0.3s ease;}  
  .header .custom-toggler.active .bar:nth-child(1) {transform: translateY(8px) rotate(45deg);}  
  .header .custom-toggler.active .bar:nth-child(2) {opacity: 0;}  
  .header .custom-toggler.active .bar:nth-child(3) {transform: translateY(-8px) rotate(-45deg);}  
  .header .dropdown-item.active, .header .dropdown-item:active {background-color: #d6d6f6;}
  
  
