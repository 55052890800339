/* .vision-bg::after{content: "";position: absolute;height: 100%;width: 100%;left: 0;top: 0;background-image: url(../../Assets/vision.png);background-repeat: no-repeat;background-position: center;background-size: 100% 100% ;z-index: -1;} */
.vision{color:#2D306F;font-size:32px;margin-bottom: 20px;}
.vision-content{color:#2D306F;font-size:38px;margin-top: 70px;}
.vision-bg{padding: 0 0;z-index: 1;}
.vision-bg ul li{position: relative;color: #FFF;margin-bottom: 14px;font-size: 22px;list-style-type: none;font-size: 20px;}
.vision-bg ul{padding-left: 0;}
/* .vision-bg ul li::after{position: absolute;
    content: "";
    background: #C1C1E9;
    height: 4px;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    border-radius: 5px;} */
    .custom-ml{margin:60px 40px;}
    .OV-padding{padding:60px 100px;}

    @media screen and (max-width:1000px) {
        .vision-bg{padding: 40px 0;}
    }
    
    @media screen and (max-width:1350px) {
        .vision-bg::after{background-image: unset;}
        .vision-content {font-size: 34px;margin-top: 0;}
        .vision, .vision-bg ul li{font-size: 18px;}
        .vision{font-size: 32px;}

    }

    @media screen and (max-width:768px) {
.vision{font-size: 24px;}
.vision-content {font-size: 22px;}
.vision-bg ul li { font-size: 14px;}
    }

    @media screen and (max-width:550px) {
        .custom-ml{ margin-left:0px;margin-top: 30px;}
        .OV-padding {
            padding: 60px 0;
        }
    }
