.form-container{max-width:550px;background-color:#5959C5;padding: 50px 20px;border-radius: 15px;position: relative;}
.form-container .form-control {background-color: #5959C5;border: 1px solid white !important;color: white;}
.form-container .form-control:focus, .form-container .form-select:focus {background-color: #5959C5 !important;}
.form-container ::placeholder{color: #FFF !important;}
.form-container .invalid-feedback{text-align: start;color: red;font-weight: 600;font-size: 12px;}
.form-container .form-select{background-color: #5959C5;border: 1px solid white !important;}
.form-container .form-select{color: #FFF !important;}

.regester_now{    position: absolute;
    top: -85px;
    left: 50%;
    transform: translateX(-50%);}