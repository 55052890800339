.setss .setscard{background: #FFF;padding: 20px;box-shadow: 0px 0px 5.96px 0px #00000059;border: 2px solid var(--brand-stroke, #C1C1E9);height: 210px;display: flex;flex-direction: column;justify-content: center;gap:20px;border-radius: 12px;margin-bottom: 20px;}
.setscard p{color: #5959C5 !important;font-weight: 600;text-align: center !important;}
.setss .setscard:hover{background: linear-gradient(90deg, rgba(72, 72, 161, 1) 0%, rgba(102, 102, 176, 1) 100%);}
.setss .setscard:hover p{color: #FFF !important;}
.setscard img{height: 88px;}
.mt-marginset{margin: 100px auto;}
@media screen and (max-width:550px) {
    .setscard p {font-size: 12px;}
    .setss p{font-size: 11px !important;}
    .mt-marginset { margin: 60px auto;}
}