.lab{box-shadow: 0px 0px 7px 0px #00000040;
}
.lab p{border: 1.8px solid;
    border-image-source: linear-gradient(7.88deg, #FFFFFF 6.38%, #C1C1E9 94.26%);
    background: #FFF;
    color: #353597;
    font-weight: 700;
    padding: 10px 20px;
    position: absolute;
    bottom: -1px;
    max-width: 40%;
    font-size: 24px;
    width: 100%;
    text-align: center;}

    .tab-label{font-size: 22px;color: #353597;border-image-source: linear-gradient(7.88deg, #FFFFFF 6.38%, #C1C1E9 94.26%);
        background: #FFF;  font-weight: 700;border: 1px solid var(--brand-stroke, #C1C1E9);
        padding: 20px 20px;border-radius:5px;width: 100%;max-width: 50%;}

        .tab-label.react-tabs__tab--selected{background-color: #5959C5;color: #fff;}
        .react-tabs__tab-list{border-bottom: 0;    display: flex;
            justify-content: center;text-align: center;
            align-items: center;
            gap:25px;}
.facilities-img img{    height: 501px;
    object-fit: cover;}

@media screen and (max-width:550px) {
    .tab-label {font-size: 16px;}
    .lab p{max-width: 50%;font-size: 16px;}
    .facilities-img img{height: unset;}
}