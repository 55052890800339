.custom-flex{margin: 40px 0;}
.legacy{display: flex;flex-direction: row;justify-content: center;gap:60px;align-items:end;}
.c-card{display: flex;
    justify-content: center;
    background: #FFF;
    color: #5959C5;width: 160px;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0px 0px 9.96px 0px #00000059;
    align-items: center;
    flex-direction: column;
    gap: 10px;}
.c-card:hover{background: linear-gradient(90deg, rgba(72, 72, 161, 1) 0%, rgba(102, 102, 176, 1) 100%);color: #FFF;}
.legacy img {height:40px;}
.legacy p{color: #5959C5;font-weight: 700;} 
.c-card:hover  p{color: #FFF;}
.topper-sec{    width: 100%;
    height: 330px;
    object-fit: contain;}

.c-card :nth-of-type(2){font-size: 12px !important;font-family: poppins;font-weight: 500;}

.admission-btn{position: absolute;left: 0;top:79px;width: unset;z-index: 1;border-radius: 0 10px 10px 0;font-weight: 700;}

.c-card:nth-child(1){font-size: 20px !important;}
.c-card:nth-child(2){font-size: 34px !important;}
.c-card:nth-child(3){font-size: 45px !important;width: 180px;}
.c-card:nth-child(4){font-size: 20px !important;}
.c-card:nth-child(5){font-size: 20px !important;}

.c-card span{font-size:34px;}
.c-card:last-child span{font-size:25px;}

.AdmissionNews .slick-dots li button:before, .AdmissionNews  .slick-dots li button:after {background-color:#D9D9D9;content: "";opacity: 1;border-radius: 5px;width:30px;height: 4px;}
.AdmissionNews .slick-dots li.slick-active button:before{background-color: #353597;border-radius: 5px;}
.AdmissionNews .slick-dots {bottom:-70px;}
.AdmissionNews .slick-dots li{width:30px;height: 4px;border-radius: 5px;}
.AdmissionNews .slick-active button{background-color: #353597;border-radius: 5px;}
.AdmissionNews .slick-dots li button{background-color: transparent !important;}
.resultCard{background-color: #FFF;
    border-radius: 15px;
    box-shadow: 0px 0px 7px 0px #00000040;    
    border: 1px solid var(--brand-stroke, #C1C1E9);
    }
.resultoverview{background: #353597;color: #FFF !important;border-radius:15px 15px 0 0;padding:10px 20px;text-align: center;font-size: 22px;font-family: "Montserrat", sans-serif !important;}
.resultCard-content p{color: #2D306F;font-weight: 600;margin-bottom: 25px;font-size: 18px;}
.resultCard-content{padding: 25px 50px;background:#f6faff;border-radius: 12px;}
.mt-margin50{margin: 100px 0 25px;}
.ptt-padding{padding: 90px 0 50px;}
.h1mob{font-weight: 700;}


@media (max-width:1300px) {
    .c-card:nth-child(3){width: 170px;}
    .custom-flex p:first-child{font-size: 14px;}
    .custom-flex h2{font-size: 24px;}
    .legacy{gap:20px; }
    .c-card{padding: 20px 7px;}
    .c-card:nth-child(2) {font-size: 20px !important;}
    .c-card:nth-child(3) {font-size: 20px !important;}
    .c-card:nth-child(4) {font-size: 20px !important;}
    .c-card:nth-child(5) {font-size: 20px !important;}
    .c-card span:last-child {font-size: 20px;}
    .admission-btn{font-size: 12px;}
    .custom-flex {margin-top: 60px;}
}

@media (max-width:767px) { 
    .legacy{gap:20px; display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-gap: 20px; /* Gap between grid items */}
.h1mob{font-size: 26px;}
.topper-sec {width: 100%;height: 150px;object-fit: contain;}
.resultCard{margin: 20px 0;}
.resultCard-content {
    padding: 25px 30px;
}
.admission-btn{top: 40px;}
.trust{font-size: 26px;
    line-height: 1.5;}
}