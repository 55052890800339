.grey-bg2{background: #F4F4FD;color: #5959C5;padding:40px 70px 40px 95px;}
.prayer p{font-size: 14px;margin-bottom: 20px;color: #5959C5;}

.grey-bg2:hover{background: #5959C5;color: #FFF;}
.grey-bg2:hover p{color: #FFF;}
.mt-up-100{padding-top: 100px;}
.ppprayer p{font-size: 19px;}
@media screen and (max-width:1000px) {
    .blue-bg2, .grey-bg2{padding: 30px;}
    .grey-bg2{    padding: 40px 70px 40px 40px;}
    .prayer h2{font-size: 22px;}
}

@media screen and (max-width:500px) {
    .grey-bg2{    padding:20px;}
}