.board{margin: 10px;width: 100%;max-width: 550px;}
.Announcement{padding:50px 0;}
.mesh{position: absolute;top:-50px;width: 100%;z-index: -1;}
.flask{position: absolute;right:0px;top:-50px;}
/* .annoncement-description{position: absolute;top:15px;left: 15px;} */
.announcement{background-image: url(../Assets/mesh.svg);background-position: center;background-size: contain;}
.newsimg img{height: 560px;max-width: 800px;margin: 0 auto;}

@media screen and (max-width:1000px) {
    .newsimg img{height: unset;max-width: unset;}
}

@media screen and (max-width:550px) {
    .board{margin: 5px;}
    .Announcement{padding:100px 25px;}
    .flask{display: none;}
}