.banner-bg{background-image: url(../../Assets/banner-bg.png);width: 100%;height:100%;object-fit: cover;background-repeat: no-repeat;background-size: cover;background-position: center;}
.bannerstyle{margin: 20px 0;}
.bannerstyle h3, .bannerstyle p{color: #FFF;}
.bannerstyle p{max-width: 250px;}

.Banner .slick-dots li button:before, .Banner .slick-dots li button:after {background-color: #eee;content: "";opacity: 1;border-radius: 5px;width:30px;height: 4px;}
.Banner .slick-dots li.slick-active button:before{background-color: #353597;border-radius: 5px;}
.Banner .slick-dots {bottom: 15px;}
.Banner .slick-dots li{width:30px;height: 4px;border-radius: 5px;}
.Banner .slick-active{background-color: #353597;border-radius: 5px;}

.mob-block{display: none !important;}


@media screen and (max-width:550px) {
    .mob-p-0{padding: 0;}
    .desk-block{display: none !important;}
    .mob-block{display: block !important;}
}