.activity{background-color: #FFF;border: 2px solid var(--brand-stroke, #C1C1E9);box-shadow: 0px 0px 7px 0px #00000040;
color:#353597;font-weight: 600;margin-top: 20px;display: block;padding:8px 5px;max-width:235px;margin:20px auto !important;}
.activity-img img{height:350px;margin: 0 auto;}
.beyond1{position: absolute;left: 0;top:65%;}
.beyond2{position: absolute;left:33%;top:65%;}
.beyond3{position: absolute;left:61%;top:40%;}
.beyond4{position: absolute;right:0;top:60%;}
.beyond5{position: absolute;right:0;top:0%;}
.boyndthebook{margin-bottom: 100px;}
@media screen and (max-width:550px) {
    .activity-img img {width: 100%;}
    .beyond2,.beyond1,.beyond3,.beyond4,.beyond5{display: none;}
    .mob-20{margin-top: 20px;}
}
