/* You can add global styles to this file, and also import other style files */
*{margin:0; padding:0; box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box; }
*:after,*:before{box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;}
table { border-collapse:collapse; border-spacing:0;}
fieldset,img{border:0;}
address,caption,cite,code,dfn,em,strong,th,var {font-style:normal; font-weight:normal; }
ol,ul { list-style:none;}
caption,th {text-align:left;}

q:before,q:after {content:'';}
abbr,acronym { border:0;}
header,nav,section,article,aside,footer,hgroup,figure {display: block; }
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {-webkit-appearance: none;margin: 0;}
/* Firefox */
/* input[type=number] {-moz-appearance: textfield;} */
.help-block{color: red;font-size: 12px;}
button:focus, .form-control:focus{outline: none ;box-shadow: none;outline-color: transparent;outline-width: 0;}
h1,h2,h3,h4,h5,h6,p,ul,ol,figure{margin-bottom: 0;}
html, body{max-width: 100%;}
.modal-content{border: 0;}
.custom-control-input:focus~.custom-control-label::before{box-shadow: none;}
a, a:hover{text-decoration: none;outline: 0;outline-color: transparent;outline-width: 0;-webkit-appearance: none;-moz-appearance: none;appearance: none;}
button{-webkit-appearance: none;-moz-appearance: none;appearance: none;}
.form-group{position: relative;}


h1{font-size: 40px;line-height:2;letter-spacing:1px;font-weight: bold;color: #5B59B1;font-family: "Montserrat", sans-serif !important; }
h2{font-size: 32px;line-height: 37.5px;letter-spacing: 0.2px;font-weight: bold;font-family: "Montserrat", sans-serif !important; }
h3{font-size: 22px;line-height: 37.5px;letter-spacing: 0.2px;font-weight: bold;color: #5B59B1;font-family: "Montserrat", sans-serif !important; }
p{font-family: "Poppins", sans-serif !important;}
/* p{font-size:16px;line-height: 36px;color: #fff;font-weight: 300;} */

.flex-coloumn{display: flex;flex-direction: column;justify-content: center;gap: 30px;}
.parentallign .flex-coloumn{height: 114px;}
.custom-card{background-color: #FFF;border-radius:26px;box-shadow: 0px 0px 7px 0px #00000040;padding: 20px;
  border: 1px solid var(--brand-stroke, #C1C1E9);margin: 20px;}
    .admission{padding: 25px;}
  .mb-5 {
    margin-bottom: 3.5rem !important;
}

body {
  margin: 0;overflow-x: hidden !important; 
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight:400;
  font-style: normal;background-color: #f8f8f8;
}

.slick-prev, .slick-next{width: 0;}

.poppins{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.line h2{text-transform: uppercase !important;}

.fw-100 {font-weight: 100;}
.fw-200 {font-weight: 200;}
.fw-300 {font-weight: 300;}
.fw-400 {font-weight: 400;}
.fw-500 {font-weight: 500;}
.fw-600 {font-weight: 600 !important;}
.fw-700 {font-weight: 700;}
.fw-800 {font-weight: 800 !important;}
.fw-900 {font-weight: 900;}
.w-100{width: 100%;}

input, select{outline: 0 !important;border:1px solid #716EE1 !important;border-radius: 10px !important;}
.form-control:focus, .form-select:focus{box-shadow: unset !important;}
.modal-content{border-radius: unset;}
.modal-header {border-bottom: 0;padding: 10px 20px 0 10px;}
.custom-btn{background-color: #5959C5;padding: 10px 20px;outline: 0;color: #FFF;border: 0;text-decoration: none;border-radius: 10px;font-weight: 600;}
.custom-btn:hover{background-color: #5959C5}
.line{color:#5959C5;text-transform: uppercase !important;}
.line::after{content: "";position: absolute;background: #5959C5;width:100px;height:2px;bottom:-10px;left: 0;}
.text-center{text-align: center;}
.mt-margine-reverse {
  margin-top: -205px !important;
  margin-bottom: 100px;
}
.form-control{font-family: "Poppins", sans-serif;}
.custom-btn{border-radius:4px}
.accor-width{max-width: 700px;}
.gallery{width: 100%;max-width:100%;padding: 0 10px !important;border-radius: 12px !important;}
.white-bg{margin-bottom: 35px !important;box-shadow: 0px 0px 7px 0px #00000040 !important;padding: 10px 5px !important;
}

.white-bg{border-radius: 10px;}

.slick-prev{z-index: 9 !important;}
.slick-next{z-index: 9 !important;}
/* .noww{margin-top: 50px;} */
.noww a{float: right !important;padding: 10px 20px !important;}
.tab-margin .tab-label{outline: 0;}

/* Style for the previous button */
.slick-prev::before{content: "" !important;
  position: absolute;
  left: -45px;
  top: 50%;transform: translateY(-50%);
  z-index: 9;opacity: 1 !important;
  background-repeat: no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
  background-image: url('./Components/Assets/prev-image.png'); /* Specify the path to the previous button image */
}

/* Style for the next button */
.slick-next::after {  content: "" !important;
  position: absolute;
  right:-45px;
  top: 50%;transform: translateY(-50%);
  z-index: 9;opacity: 1 !important;
  background-repeat: no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
  background-image: url('./Components/Assets/next-image.png'); /* Specify the path to the next button image */
}

.accordion-button{font-size: 24px !important;border-bottom: 1px solid #5959c533 !important;}
.kite2{top: 132px !important;}
.activity {
  background-color: #FFF;
  border: 2px solid var(--brand-stroke, #C1C1E9);
  box-shadow: 0px 0px 7px 0px #00000040;
  color: #353597;
  font-weight: 600;border-radius:6px;
  margin-top: 20px;
  display: block;
  padding: 8px 5px;
  max-width: 180px;
  margin: 0 auto;
  margin-top: 20px;
}
.apply-dwn-margin{margin-bottom: 100px;}
.blue-content h3{text-transform: uppercase;}
.mobcross{display: none;}
.mt-margin{margin: 100px auto;}
.h-100{height: 100%;}
.Admissionn .subcontent,.Admissionn .subcontent2{margin-bottom: 0 !important;}
.tab-label{text-transform: uppercase;}
.setscard:hover{background: linear-gradient(90deg, rgba(72, 72, 161, 1) 0%, rgba(102, 102, 176, 1) 100%);}
.setscard:hover h3{color: #FFF;}
.navbar-expand-lg .offcanvas .offcanvas-body{justify-content: space-evenly !important;}
.activity.programm{border-radius: 10px;}
.school-left{padding-left: 130px;}
 .school-left p, .Curriculum p{color:#5959C5;}
 .light-blue{color: #2D306F;}
.carbg::before, .ammission-mt::before{content: "";position: absolute;background-image: url(./Components/Assets/hash.png);background-repeat: no-repeat;background-size: contain;right:0;    top: -120px;
  height: 200px;width: 200px;}
  .ammission-mt{position: relative;}
  .ammission-mt::before{background-image: url(./Components/Assets/hat.png);top: -35px;height: 100px;width: 100px;}
  .wave2{z-index: 0;}
  .wave2::after{content: "";background-image: url(./Components/Assets/wave.svg);background-position: center;background-size: cover;
    position: absolute;
    top: 20%;
    width: 100%;
    height: 500px;
    z-index: -1;
}
.testi p{font-size: 14px !important;}
.event-banner-content h2, .event-banner-content span{text-transform: uppercase;}
.welcomenewp p{max-width: 600px !important;}
.testi{height:500px;overflow: hidden;margin-bottom: 0 !important;}
.admission h3 {max-width: 230px !important;}
.pt-padding{padding:50px 0 100px;}

.slick-next:before{content: "" !important;}
.welcome p{font-family: "Poppins", sans-serif;}
.mb-4 {margin-bottom: 62px !important;}
.mtmargin{margin-bottom: 50px;}
.primaryrow{max-width: 1000px;}
.Gallery{margin-top: 30px;}
.stu-suc-stories{color:#2D306F; max-width:320px; margin-left:45px; font-size:15px; font-weight: 500}
.mt-reverse {margin-top: -190px;}
.History.histry .rectangle img{height: 560px;object-fit: contain;}

.accrwidth .infowidth, .accrwidth .infowidth{width: 100% !important;}
.setscard h3{text-transform: uppercase;}
.boxx{box-shadow: 0px 0px 9.96px 0px #00000059;}
.tab-margin .tab-label{padding: 15px 0;}
@media screen and (min-width:1400px) {
  .vedio-container{max-width: 1320px;margin: 0 auto;}
.findus-width{max-width: 1320px;margin: 0 auto;}
.stu-suc-stories{max-width: 260px;margin: 0 auto;}
.wave {top: 13%;}
.img1, .img3, .img4{height: 225px !important;}
.vision-bg{height: unset !important;padding: 60px 0 !important;}
.History{max-width: 1380px;margin: 0 auto;}
.mt-up-100, .school-w, .Gallery.mt-margin, .banner-bg, .tab-margin, .AdmissionNews, .blue-bg, .wave2, .footer, .grey-bg, .dark, .vision-bg ,.blue-bg3, .carbg{max-width: 1320px !important;margin: 0 auto;}
.mesh{display: none;}
.wave2::after{left: 50%;transform: translateX(-50%);}
.custom-width2{margin: 0 auto !important;}
.mt-margin{margin: 100px auto !important;}
.pl-120{padding-left: unset !important;}


}

@media screen and (min-width:1370px) {
.find-us img{height: 252px;}
.img2{height: 510px;}
}

@media screen and (max-width:1300px) {
  .board-large{width: 100%;}
.school-left{padding-left:30px;}
.rectangle img{width: 100%;}
 .rectangle::after{display: none;}
 .card-details h3{font-size: 16px;}
 .slick-prev::before{left: 0px !important;}
 .slick-next::after{right: 0px !important;}
.find-us .img3,.find-us .img1, .find-us .img4{height: 220px !important;}
.pl-120 {padding-left: 20px !important;}
.resultCard-content {padding: 25px 30px !important;}
.mt-reverse {margin-top: -130px;}
.footerlogo{height: 240px;}
.rect2{display: none;}
.crest{margin-top: 50px !important;}
}
@media screen and (max-width:1150px) {

  .find-us .img3, .find-us .img1, .find-us .img4{height: 192px !important;object-fit: cover !important;}
}


@media screen and (max-width:1000px) {
  .mt-reverse2{margin-top: -70px !important;}
  .admission p{font-size: 13px !important;}
  .mt-reverse {margin-top: -85px !important;}
  .welcome p{text-align: start !important;}
  .choice p{text-align: start !important;}
  .choice{padding: 0 10px !important;} 
  .admission a{font-size: 12px !important;}
  .admission h3 {font-size: 14px !important;}
  .find-us .img3,.find-us .img1, .find-us .img4{height: 130px !important;}
  .alumnimeet .custom-card {
    max-width: 300px;
    
  }
}

@media screen and (max-width:550px) {
  .mt-reverse2{margin-top: -140px !important;}
  .line {color: #5959C5;font-size: 22px;}
  .slick-next::after{right: 0;height: 50px;width: 50px;z-index: 9;}
  .slick-prev::before{left: 0;height: 50px;width: 50px;z-index: 9;}
  .slick-prev::before{left: -20px !important;}
  .slick-next::after{right: -20px !important;}
  .slick-prev{left: 0 !important;}
  .apply-dwn-margin{margin-bottom: 70px;}
  .slick-next{right: 0 !important;}
  .row{margin-right: unset !important;margin-left: unset !important;}
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    --bs-gutter-x: 2.5rem !important;}
    .school-left { padding-left: 30px;}
    .find-us .img3,.find-us .img1, .find-us .img4{height: unset !important;margin: 10px;}
    .find-us .img2{margin: 10px;}
    /* .EVENTS img{height: 400px;object-fit: cover;} */
    .event-banner-content{width: 100% !important;}
    .event-banner-content h2, .event-banner-content span{font-size: 13px;}
    .grey-round {height: 4px !important;width: 15px !important;border-radius: 2px;}
    .Ceremony {padding: 50px 0 !important;}
    img.rectangle{width: 100%;}
    .Ceremony .rectangle::after {height: 160px !important;width: 160px !important;}
.topper {padding: 0 15px;}
.topper h2{font-size: 26px;}
.card a{font-weight: 600 !important;}
.History .rectangle img{margin-bottom: 50px !important;}
/* .aboutGallery .container{padding: 0;} */
.aboutGallery .tab-margin, .tab-margin{padding: 0;}
.tab-margin .tab-label{font-size: 12px !important;padding: 5px 20px;
  display: flex;justify-content: center;
  align-items: center;}
  .tab-margin .tab-label:nth-of-type(5),.tab-margin .tab-label:nth-of-type(4){border-radius: 0 !important;}
  .topper h2, .topper h3{font-size: 20px;}
  .topper p{margin-bottom: 25px;}
.blue-content h3{padding-left: 15px;}
.content {font-size: 14px !important;}
.card {margin-bottom: 10px;}
.pt-padding {padding: 50px 0 50px !important;}
.aboutGallery .tab-margin {left: 0;}
.accordion {padding: 0px !important;}
.mob-banner-h{padding: 0 !important;}
.alumnimeet .custom-card {max-width: 280px !important;height: 215px !important;}
.num{padding: 10px !important;width: 55px;font-weight: 800 !important;}
.mob-5{padding: 20px !important;}
.mob-block{padding: 0 12px;}
.Banner .slick-dots{bottom:-25px !important;}
.event-banner-content h2 {font-size: 30px !important;line-height: 1.3;}
  .modal-dialog { width: 95% !important;top: 25px;margin: 0  auto!important;}
  .ptt-padding {padding: 0 !important;}
  .mt-margin50 { margin:50px 0 0 !important;}
  .mt-100{margin-top: 0 !important;}
  .mt-up-100 {padding-top: 0px;}
  .pt-pad {padding-top: 0;}
  .accor-width{margin: 10px;}
  .featured-mt{padding: 0px 0 !important;} 
  .AdmissionNews.ptt-padding{padding: 50px 0 0!important;}
  .mb-4 {margin-bottom: 50px !important;}
  .mbb-100{margin-bottom: 50px;}
  .find-us {margin: 100px 0 50px !important;}
  .findus-width{max-width: 325px !important;margin: 0 auto !important;}
  .accordion-button{font-size: 18px !important;}
  .c-card{width: 140px !important;margin: 0 auto !important;}
  .c-card:nth-child(3){width: 140px !important;}
  /* .find-us img{max-width: 88%;margin: 0 auto;} */
  .mt-margin {margin: 60px auto 0 !important;}
  .mt-up-100 {padding-top: 60px !important;}
  .mesh-bg{padding: 40px 0 !important;}
  .custom-width, .custom-width2{padding-bottom: 0px !important;}
  .housecard-margin{margin-bottom: 80px !important;}
  .housecard-margin::before{bottom: -80px !important;}
  .setscard{height: 90px !important;}
  .setss .setscard{height: 140px !important;}
  .programme h3{font-size: 11px;}
  .pt-pad{padding-top: 0 !important;margin: 0 !important;}
.collon{height: 35px;margin-bottom: 8px;}
.carbg::before{display: none;}
.faculties2{display: none;}
.Gallery{margin-top: 60px;}
.kite2{display: none;}
.custom-card p{font-size: 12px !important;}
.card{gap: 10px !important;}
.card2 h3, .card2 p{font-size: 9px !important;}
.card2{padding: 5px 10px !important;}
.mobcross{display: block;position: absolute;top: 0;right: -5px;}
.mt-reverse {margin-top: -45px !important;}
.pop{display: none;}
.mob-p-00{padding: 0 !important;}
.setscard img {height: 50px !important;}
.activity{font-size: 13px !important;margin: 8px 0 30px !important;}
.setscard{margin-bottom: 8px !important;}
.mob-block{padding: 0 !important;}
.setss .setscard{margin-bottom: 20px !important;}
.container.Admissionn{margin-bottom: 100px;}
.subcontent.fw-600{margin-top: 20px;}
.blue-content h3{font-size: 23px;
  line-height: 30px;
  padding-left: 0;}

.breadcrumbs span{font-size: 10px !important;}


}

@media screen and (max-width:390px) {
.tab-margin .tab-label{padding: 5px !important;}
}
@media screen and (max-width:350px) {
.left-img1, .left-img2{display: none;}
}

@media screen and (min-width: 1350px) and (max-width: 1363px) {
  .vision-bg::after {
    top: -45px;
  }
}


@media (max-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm {
      max-width: 1150px !important;
  }
}

.tab-label.react-tabs__tab--selected{border: 1px solid #fff;}
.tab-label{    border: 1px solid var(--brand-stroke, #C1C1E9);}

