.custom-dflex{display: flex;align-items: center;gap: 20px;justify-content: space-between;}



.marquee-img {
    /* animation: 5s linear infinite marquee; */
    height: 120px;
    width: 240px;
    /* margin: 50px 0; */
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.featured-mt{padding:20px 0 100px 0;}

.mesh{position: absolute;top:-50px;width: 100%;}


@media only screen and (max-width:1000px) {
    .marquee-img {
        height: 120px;
        width: 160px;}
}

@media only screen and (max-width:550px) {
    .custom-dflex{flex-direction: column;}
}
