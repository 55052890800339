

.find-us img {
  max-width: 100%;height: 237px;
}

.findimg1,.findimg2 {margin-bottom:10px;}
.social-pos{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);text-align: center;}
.social-pos img{height:30px;margin-bottom:8px;}
.social-pos p{color: #FFF;font-weight: 600;}
.kite{position: absolute;right: 0;top: 0;}

.find-us{margin: 100px 0;}

@media only screen and (max-width:"376px") {
  .find-us img {
    margin-bottom: 10px !important;
  } 
}
