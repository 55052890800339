
.card-details h3{color: #353597;line-height: 1.5;font-size: 19px;}
.card-details p{color: #2D306F;font-weight: 400;margin-bottom: 0 !important;}
.custom-card p{color: #2D306F;font-weight: 500;}
.wave{position:absolute;top:25%;width: 100%;height:500px;z-index: 0;}
.ratingstar{width: 120px;margin-left: -15px;}
.kite{position: absolute;right: 0;top: 0;}
@media screen and (max-width:550px) {
    .kite{width: 110px;}
    .custom-card p{font-size: 14px;}
    .card-details h3{font-size: 16px;}
.activity{margin: 20px auto 0 !important;}

}
