.footer{background:linear-gradient(90deg, #6768c6, #363699);padding:50px 0;}
.footer p{font-size: 22px;font-weight:600;}
.footer ul li, .footer p{color: #FFF !important;margin-bottom: 24px;}
.footer ul {padding-left: 0;}
.footer .container{max-width:1300px;}
.footer ul li{font-size: 15px !important;font-weight: 500;}
.footer ul li a{color: #FFF;}
.grey-bg{color:#353597;background-color: #F8F8F8;padding: 10px 60px 0 30px;text-align: center;}
.portal p{color: #353597 !important;font-weight: 500;margin-bottom: 0;font-size: 18px;}
.mob-md-3{margin-bottom: 20px;margin-top:-22px;}
.footerlast{display:flex;justify-content: center;align-items: center;}
.footer{padding: 30px 0 20px;}
.portallink{padding: 10px 20px;border-radius: 15px;}
.fw-600{font-weight: 600 !important;}
.footer .fw-800{font-family: Montserrat !important}
@media screen and (max-width:1000px) {
    .footerlogo{height: 200px;}
    .portal p {font-size: 12px;}
    .footer .custom-card{padding: 10px;}
    .footerlast{flex-direction: column;justify-content: center;gap: 20px;}
}


@media screen and (max-width:550px) {
   
    .portal p {font-size: 16px !important;margin-left: 15px;}
    .footer .custom-card{padding:20px;}
    .footerlast{margin-bottom: 15px;}
}